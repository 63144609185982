import axios from 'axios';
import LZString from 'lz-string';

const API_URL = process.env.VUE_APP_API_URL || '';
const CACHE_EXPIRY_TIME = 1000 * 60 * 5;  // 5 minutes
const MAX_RETRIES = 3;

function setCache(key, data) {
  const compressedData = LZString.compress(JSON.stringify(data));
  const cacheEntry = {
    data: compressedData,
    expiry: Date.now() + CACHE_EXPIRY_TIME,
  };
  localStorage.setItem(key, JSON.stringify(cacheEntry));
}

function getCache(key) {
  const cacheEntry = localStorage.getItem(key);
  if (cacheEntry) {
    const parsedEntry = JSON.parse(cacheEntry);
    if (parsedEntry.expiry > Date.now()) {
      const decompressedData = LZString.decompress(parsedEntry.data);
      return JSON.parse(decompressedData);
    }
    localStorage.removeItem(key);
  }
  return null;
}

async function fetchWithRetries(url, options, retries = MAX_RETRIES) {
  try {
    const response = await axios(url, options);
    return response.data;
  } catch (error) {
    if (retries > 0) {
      console.warn(`Retrying... (${MAX_RETRIES - retries + 1})`);
      return fetchWithRetries(url, options, retries - 1);
    }
    throw error;
  }
}

export async function getAvatarByStorefrontLink(storefrontLink) {
  if (!storefrontLink) {
    console.error('Invalid storefrontLink: ', storefrontLink);
    return null;
  }

  const cacheKey = `avatar_${storefrontLink}`;
  const cachedData = getCache(cacheKey);
  if (cachedData) {
    return cachedData;
  }

  const response = await fetchWithRetries(`${API_URL}/api/avatars/storefront/${storefrontLink}`);
  setCache(cacheKey, response);
  return response;
}

export async function getAvatarTraits() {
  const cacheKey = 'avatar_traits';
  const cachedData = getCache(cacheKey);

  if (cachedData) {
    return cachedData;
  }

  const response = await fetchWithRetries(`${API_URL}/api/avatar-traits`);
  setCache(cacheKey, response);
  return response;
}


// Add this function to your existing api.js file

export async function searchAvatarTraits(query) {
  const cacheKey = `avatar_traits_search_${query}`;
  const cachedData = getCache(cacheKey);

  if (cachedData) {
    return cachedData;
  }

  const response = await fetchWithRetries(`${API_URL}/api/avatar-traits/search`, {
    params: { query },
    timeout: 10000
  });

  setCache(cacheKey, response);
  return response;
}


export async function getAvatars(page = 1, perPage = 75, searchQuery = '', sortBy = 'recent', artistName = '') {
  const cacheKey = `avatars_${searchQuery.toLowerCase()}_${artistName.toLowerCase()}_${page}_${perPage}_${sortBy}`;
  const cachedData = getCache(cacheKey);

  if (cachedData) {
    return cachedData;
  }

  const response = await fetchWithRetries(`${API_URL}/api/avatars`, {
    params: { query: searchQuery, page, perPage, sortBy, artistName },
    timeout: 10000
  });

  setCache(cacheKey, response);
  return response;
}
