import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/styles.css';

const app = createApp(App);

app.use(router);
app.use(store);

app.config.errorHandler = (err, vm, info) => {
  console.error('Error:', err);
  console.error('Component:', vm);
  console.error('Info:', info);
};

app.mount('#app');
