<template>
  <div id="app">
    <header class="app-header">
      <router-link class="header-link" to="/">AvatarTraits.xyz</router-link>
    </header>
    <NavbarComponent @search="handleSearch" />
    <router-view :searchQuery="searchQuery"></router-view>
  </div>
</template>

<script>
import NavbarComponent from './components/Navbar.vue';

export default {
  name: 'App',
  components: {
    NavbarComponent,
  },
  data() {
    return {
      searchQuery: '',
    };
  },
  methods: {
    handleSearch(query) {
      this.searchQuery = query;
    },
  },
};
</script>

<style>
#app {
  font-family: Arial, sans-serif;
  margin: 20px;
}

.app-header {
  text-align: center;
  margin-bottom: 20px;
}

.header-link {
  text-decoration: none;
  color: #333;
  font-size: 24px;
  font-weight: bold;
}
</style>
