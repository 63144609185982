<template>
    <div class="artist-page">
      <h1>{{ decodedArtistName }}</h1>
      <AvatarList :searchQuery="decodedArtistName" :selectedTags="[]" />
    </div>
  </template>
  
  <script>
  import AvatarList from './AvatarList.vue';
  
  export default {
    name: 'ArtistPage',
    components: {
      AvatarList,
    },
    props: {
      artistName: {
        type: String,
        required: true,
      },
    },
    computed: {
      decodedArtistName() {
        return decodeURIComponent(this.artistName);
      },
    },
  }
  </script>
  
  <style scoped>
  .artist-page {
    padding: 20px;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  </style>
  