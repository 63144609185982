import { createRouter, createWebHistory } from 'vue-router';
import AvatarList from '../components/AvatarList.vue';
import AvatarDetails from '../components/AvatarDetails.vue';
import ArtistPage from '../components/ArtistPage.vue';
import AvatarBuilder from '../components/AvatarBuilder.vue';

const routes = [
  {
    path: '/',
    name: 'AvatarList',
    component: AvatarList,
  },
  {
    path: '/avatar/:storefront_link',
    name: 'AvatarDetails',
    component: AvatarDetails,
    props: true,
  },
  {
    path: '/avatar/shop/artist/:artistName',
    name: 'ArtistPage',
    component: ArtistPage,
    props: true,
  },
  {
    path: '/SecretBeta_builder',
    name: 'AvatarBuilder',
    component: AvatarBuilder,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
