import { createStore } from 'vuex';
import { getAvatars } from '../services/api';

export default createStore({
  state: {
    avatars: [],
    selectedTraits: {},
  },
  mutations: {
    setAvatars(state, avatars) {
      state.avatars = avatars;
    },
    setSelectedTrait(state, { category, trait }) {
      state.selectedTraits = {
        ...state.selectedTraits,
        [category]: trait
      };
    },
    removeSelectedTrait(state, category) {
      const newSelectedTraits = { ...state.selectedTraits };
      delete newSelectedTraits[category];
      state.selectedTraits = newSelectedTraits;
    },
  },
  actions: {
    async fetchAvatars({ commit }) {
      try {
        const avatars = await getAvatars();
        commit('setAvatars', avatars);
      } catch (error) {
        console.error('Error fetching avatars:', error);
      }
    },
    toggleTrait({ commit, state }, trait) {
      if (state.selectedTraits[trait.type]?.url === trait.url) {
        commit('removeSelectedTrait', trait.type);
      } else {
        commit('setSelectedTrait', { category: trait.type, trait });
      }
    },
  },
});
