<template>
    <div class="loading-spinner">
      <div class="spinner"></div>
      <p>{{ loadingMessage }}</p>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      loadingMessage: {
        type: String,
        default: 'Loading...'
      }
    }
  };
  </script>
  
  <style scoped>
  .loading-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 8px solid #3498db;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  </style>
  