<template>
  <div class="avatar-builder">
    <div class="preview-section">
      <div class="avatar-preview">
        <template v-for="category in traitOrder" :key="category">
          <img 
            v-if="selectedTraits[category]"
            :src="selectedTraits[category].url"
            :alt="category"
            class="trait-image"
            :style="{ zIndex: traitOrder.length - traitOrder.indexOf(category) }"
          />
        </template>
      </div>
    </div>
    <div class="selection-section">
      <input 
        v-model="searchQuery" 
        @input="debounceSearch"
        placeholder="Search traits..."
        class="search-input"
      />
      <div class="category-tabs">
        <button 
          v-for="category in traitOrder" 
          :key="category"
          @click="changeCategory(category)"
          :class="{ active: activeCategory === category }"
          class="category-tab"
        >
          {{ formatCategoryName(category) }}
        </button>
      </div>
      <div v-if="loading" class="loading">Loading...</div>
      <div v-else class="traits-grid">
        <div 
          v-for="trait in displayedTraits" 
          :key="trait.url"
          @click="toggleTrait(trait)"
          class="trait-item"
          :class="{ selected: isTraitSelected(trait) }"
        >
          <img :src="trait.url" :alt="trait.type" class="trait-thumbnail" />
          <p class="trait-avatar-name">{{ trait.avatarName }}</p>
        </div>
      </div>
      <div v-if="hasMoreTraits" class="load-more">
        <button @click="loadMoreTraits" :disabled="loading">Load More</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { getAvatarTraits, searchAvatarTraits } from '../services/api';
import debounce from 'lodash/debounce';

const traitOrder = [
  'accessory_back',
  'accessory',
  'head_accessory',
  'hair_back',
  'hair_front',
  'face_upper',
  'face_lower',
  'body',
  'body_bottom'
];

const TRAITS_PER_PAGE = 20;

export default {
  name: 'AvatarBuilder',
  data() {
    return {
      availableTraits: {},
      searchQuery: '',
      activeCategory: traitOrder[0],
      traitOrder,
      page: 1,
      loading: false,
      isSearching: false,
    };
  },
  computed: {
    ...mapState(['selectedTraits']),
    displayedTraits() {
      if (this.isSearching) {
        return this.availableTraits[this.activeCategory] || [];
      }
      const traits = this.availableTraits[this.activeCategory] || [];
      const startIndex = 0;
      const endIndex = this.page * TRAITS_PER_PAGE;
      return traits.slice(startIndex, endIndex);
    },
    hasMoreTraits() {
      if (this.isSearching) return false;
      const traits = this.availableTraits[this.activeCategory] || [];
      return this.page * TRAITS_PER_PAGE < traits.length;
    },
  },
  methods: {
    ...mapActions(['toggleTrait']),
    async loadTraits() {
      this.loading = true;
      try {
        const traits = await getAvatarTraits();
        this.availableTraits = traitOrder.reduce((acc, category) => {
          acc[category] = traits.filter(trait => trait.type === category);
          return acc;
        }, {});
      } catch (error) {
        console.error('Error loading traits:', error);
      } finally {
        this.loading = false;
      }
    },
    isTraitSelected(trait) {
      return this.selectedTraits[trait.type]?.url === trait.url;
    },
    formatCategoryName(category) {
      return category.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    },
    debounceSearch: debounce(async function() {
      if (this.searchQuery.trim() === '') {
        this.isSearching = false;
        await this.loadTraits();
        return;
      }
      
      this.loading = true;
      this.isSearching = true;
      try {
        const searchResults = await searchAvatarTraits(this.searchQuery);
        this.availableTraits = traitOrder.reduce((acc, category) => {
          acc[category] = searchResults.filter(trait => trait.type === category);
          return acc;
        }, {});
      } catch (error) {
        console.error('Error searching traits:', error);
      } finally {
        this.loading = false;
      }
    }, 300),
    loadMoreTraits() {
      if (!this.isSearching) {
        this.page += 1;
      }
    },
    changeCategory(category) {
      this.activeCategory = category;
      if (!this.isSearching) {
        this.page = 1;
      }
    },
  },
  created() {
    this.loadTraits();
  },
};
</script>

<style scoped>
.avatar-builder {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.preview-section {
  width: 100%;
  max-width: 200px;
  margin-bottom: 20px;
}

.avatar-preview {
  position: relative;
  width: 100%;
  padding-bottom: 150%; /* 2:3 Aspect Ratio (150% = 3/2 * 100) */
  border: 2px solid #FF4500; /* Reddit Orange */
  border-radius: 10px;
  overflow: hidden;
}

.trait-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.selection-section {
  width: 100%;
  max-width: 800px;
}

.search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
}

.category-tabs {
  display: flex;
  overflow-x: auto;
  margin-bottom: 10px;
}

.category-tab {
  padding: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.category-tab.active {
  font-weight: bold;
  border-bottom: 2px solid #007bff;
}

.traits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
}

.trait-item {
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.trait-item.selected {
  border-color: #007bff;
}

.trait-thumbnail {
  width: 100%;
  height: auto;
  display: block;
}

.trait-avatar-name {
  margin-top: 5px;
  font-size: 0.8em;
  color: #FF4500; /* Reddit Orange */
  text-align: center;
  word-wrap: break-word;
  max-width: 100%;
}

.load-more {
  text-align: center;
  margin-top: 20px;
}

.load-more button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.load-more button:hover:not(:disabled) {
  background-color: #0056b3;
}

.load-more button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.loading {
  text-align: center;
  margin: 20px 0;
  font-size: 18px;
  color: #666;
}

@media (max-width: 600px) {
  .avatar-builder {
    padding: 10px;
  }

  .preview-section {
    max-width: 150px;
  }

  .traits-grid {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  }
}
</style>
