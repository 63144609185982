<template>
  <div>
    <div v-if="loading && !avatars.length">
      <LoadingSpinner loadingMessage="Loading avatars..." />
    </div>
    <div v-else>
      <div v-if="avatars.length > 0" class="avatar-grid">
        <template v-for="(avatar, index) in avatars" :key="avatar.id">
          <div class="avatar-item">
            <router-link :to="{ name: 'AvatarDetails', params: { storefront_link: avatar.storefront_link } }">
              <div class="avatar-image-container">
                <img :src="avatar.image_url" :alt="avatar.avatar_name" class="avatar-image" />
              </div>
              <div class="avatar-text">
                <p class="avatar-name">{{ avatar.avatar_name }}</p>
                <router-link :to="{ name: 'ArtistPage', params: { artistName: encodeURIComponent(avatar.artist_name) } }">
                  <p class="artist-name">{{ avatar.artist_name ? `by ${avatar.artist_name}` : '&nbsp;' }}</p>
                </router-link>
              </div>
            </router-link>
          </div>
          <div v-if="index === avatarsPerRow - 1" class="discord-banner">
            <img src="@/assets/discord_icon.png" alt="Discord" class="discord-icon" />
            <a href="https://discord.gg/AvatarAlpha" target="_blank" rel="noopener noreferrer" class="discord-link">
              Join us on Discord now!
            </a>
          </div>
        </template>
      </div>
      <div v-else>
        <p>No avatars found.</p>
      </div>
      <div v-if="hasMorePages" class="load-more">
        <button @click="loadMore" :disabled="loading">Load More</button>
      </div>
    </div>
  </div>
</template>


<script>
import { getAvatars } from '../services/api';
import LoadingSpinner from './LoadingSpinner.vue';

export default {
  components: {
    LoadingSpinner,
  },
  props: {
    searchQuery: {
      type: String,
      required: true,
    },
    selectedTags: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      avatars: [],
      page: 1,
      perPage: 75,
      loading: false,
      totalPages: 0,
      sortBy: 'recent',
      avatarsPerRow: 5, // Adjust this based on your layout
    };
  },
  computed: {
    hasMorePages() {
      return this.page < this.totalPages;
    },
  },
  watch: {
    searchQuery() {
      this.resetAndLoad();
    },
    selectedTags() {
      this.resetAndLoad();
    },
  },
  methods: {
    async loadAvatars() {
      this.loading = true;
      try {
        const response = await getAvatars(this.page, this.perPage, this.searchQuery, this.sortBy);
        this.avatars = [...this.avatars, ...response.avatars];
        this.totalPages = response.totalPages;
      } catch (error) {
        console.error('Error fetching avatars:', error);
      } finally {
        this.loading = false;
      }
    },
    loadMore() {
      if (this.hasMorePages) {
        this.page++;
        this.loadAvatars();
      }
    },
    resetAndLoad() {
      this.avatars = [];
      this.page = 1;
      this.sortBy = this.searchQuery ? 'name' : 'recent';
      this.loadAvatars();
    },
    updateAvatarsPerRow() {
      // Adjust avatarsPerRow based on screen width
      if (window.innerWidth >= 1200) {
        this.avatarsPerRow = 5;
      } else if (window.innerWidth >= 992) {
        this.avatarsPerRow = 4;
      } else if (window.innerWidth >= 768) {
        this.avatarsPerRow = 3;
      } else {
        this.avatarsPerRow = 2;
      }
    },
  },
  created() {
    this.loadAvatars();
    this.updateAvatarsPerRow();
    window.addEventListener('resize', this.updateAvatarsPerRow);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateAvatarsPerRow);
  },
};
</script>

<style scoped>
.avatar-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
}

.avatar-item {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.avatar-image-container {
  width: 100%;
  padding-bottom: 133.33%; /* 3:4 aspect ratio */
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.avatar-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-text {
  height: 50px; /* Fixed height for text area */
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.avatar-name, .artist-name {
  margin: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.avatar-name {
  font-weight: bold;
}

.artist-name {
  font-size: 0.9em;
  color: #666;
}

.load-more {
  text-align: center;
  margin-top: 20px;
}

.load-more button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}

.discord-banner {
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  margin: 20px 0;
}

.discord-icon {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.discord-link {
  color: #7289da;
  font-size: 1.2em;
  font-weight: bold;
  text-decoration: none;
}

.discord-link:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .avatar-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .avatar-item {
    font-size: 0.9em;
  }

  .discord-banner {
    flex-direction: column;
    text-align: center;
  }

  .discord-icon {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
</style>
