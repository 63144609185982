<template>
  <div v-if="loading">
    <LoadingSpinner loadingMessage="Loading avatar details..." />
  </div>
  <div v-else-if="avatar" class="avatar-details">
    <h2>{{ avatar.avatar_name }} by {{ avatar.artist_name }}</h2>
    <div class="image-container">
      <img :src="avatar.image_url" :alt="avatar.avatar_name" class="avatar-img" />
      <img :src="avatar.background_image_url" :alt="'Background'" class="avatar-img" />
    </div>
    <div class="traits-container">
      <div v-for="(traitUrls, traitType) in orderedTraits" :key="traitType" class="trait-item">
        <div class="trait-header">{{ traitTypeLabel(traitType) }}</div>
        <div class="trait-content">
          <div class="accessory-background"></div>
          <img
            v-for="url in traitUrls"
            :key="url"
            :src="url"
            :alt="traitTypeLabel(traitType)"
            class="accessory-img"
          />
        </div>
      </div>
    </div>
    <div class="links-container">
      <a :href="getRcaxUrl(avatar.id)" target="_blank" class="link-item">
        <img src="https://marketplace.rcax.io/rcaxLogo.svg" alt="RCAX Logo" class="link-logo" />
        <span>View on RCAX Marketplace</span>
      </a>
      <a :href="getRedditUrl(avatar.storefront_link)" target="_blank" class="link-item">
        <img src="https://www.redditinc.com/assets/images/site/Reddit_Lockup_Logo.svg" alt="Reddit Logo" class="link-logo" />
        <span>View on Reddit.com</span>
      </a>
    </div>
  </div>
  <div v-else>
    <p>Loading avatar details...</p>
  </div>
</template>

<script>
import { getAvatarByStorefrontLink } from '../services/api';
import LoadingSpinner from './LoadingSpinner.vue';

export default {
  components: {
    LoadingSpinner,
  },
  props: {
    storefront_link: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      avatar: null,
      loading: true,
      traitTypes: [
        { key: 'accessory_back', label: 'Right Hand' },
        { key: 'accessory', label: 'Left Hand' },
        { key: 'head_accessory', label: 'Head Accessory' },
        { key: 'hair_front', label: 'Hair Front' },
        { key: 'face_upper', label: 'Eyes' },
        { key: 'face_lower', label: 'Face' },
        { key: 'body', label: 'Body' },
        { key: 'body_bottom', label: 'Body Bottom' },
        { key: 'hair_back', label: 'Hair Back' },
      ],
    };
  },
  computed: {
    orderedTraits() {
      if (!this.avatar) return null;

      const traits = {};
      this.traitTypes.forEach(trait => {
        if (Array.isArray(this.avatar[trait.key])) {
          traits[trait.key] = this.avatar[trait.key];
        }
      });
      return traits;
    },
  },
  async created() {
    try {
      console.log('Fetching avatar with link:', this.storefront_link);
      const avatar = await getAvatarByStorefrontLink(this.storefront_link);
      if (avatar) {
        this.avatar = avatar;
      }
    } catch (error) {
      console.error('Error fetching avatar details:', error);
    }
    this.loading = false;
  },
  methods: {
    traitTypeLabel(key) {
      const trait = this.traitTypes.find(trait => trait.key === key);
      return trait ? trait.label : key;
    },
    getRcaxUrl(avatarId) {
      const contractAddress = avatarId.split('_')[2];
      return `https://marketplace.rcax.io/collection/0x${contractAddress}`;
    },
    getRedditUrl(storefrontLink) {
      return `https://www.reddit.com/avatar/shop/product/${storefrontLink}`;
    },
  },
};
</script>

<style scoped>
.avatar-details {
  text-align: center;
}

.image-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  max-width: 100%;
}

.avatar-img {
  width: 100%;
  max-width: 150px; /* Adjust max-width for responsiveness */
  height: auto;
}

.traits-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.trait-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
  height: auto;
  margin-bottom: 20px;
}

.trait-header {
  margin-bottom: 5px;
  font-weight: bold;
}

.trait-content {
  position: relative;
  width: 120px;
  height: calc(120px * (1200 / 760));
  overflow: hidden;
}

.accessory-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://i.imgur.com/S7sOCwf.png');
  background-size: cover;
  background-position: center;
}

.accessory-img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
}

.links-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.link-item {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.link-logo {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
</style>
